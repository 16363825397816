<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidación: {{ id }}</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/tep">Tep</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Formulario {{ accion }} Liquidaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <!---- Campo nombre de la tarifa ---->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="sitio">Sitio</label>
                      <v-select
                        :class="[
                          $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sitio"
                        placeholder="Seleccione Sitio..."
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.sitios"
                        @input="selectSitio()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="[
                          $v.form.empresa_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="empresa"
                        placeholder="Seleccione Empresa..."
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_ini">Fecha Inicio</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_ini"
                        :class="
                          $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_fin">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                        :class="
                          $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="obs">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        id="obs"
                        cols="20"
                        rows="2"
                        v-model="form.observaciones"
                        :disabled="estadodis"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        id="estado"
                        v-model="form.estado"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          :disabled="
                            estado.numeracion === 2 &&
                              $route.params.accion == 'Crear'
                          "
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label for="iva">Incluye IVA</label>
                      <input
                        type="checkbox"
                        v-model="form.incluye_iva"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Tipo de Liquidación -->
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Seleccione para Liquidar</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <!---- Campo nombre de la tarifa ---->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="operacion">Operación</label>
                      <v-select
                        :class="[
                          $v.form.operaciones.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.operaciones"
                        placeholder="Seleccione Operacion..."
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        multiple
                        :options="listasForms.operaciones"
                        :disabled="estadodis"
                        @input="validarLiquidacion()"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      form.operaciones &&
                        form.operaciones.find((o) => o.numeracion === 2)
                    "
                  >
                    <div class="form-group">
                      <label for="area">Area</label>
                      <v-select
                        :class="[
                          $v.form.areas.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.areas"
                        placeholder="Seleccione Area..."
                        label="descripcion"
                        :options="listasForms.areas"
                        multiple
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="area">Tipo Vehículo</label>
                      <v-select
                        :class="[
                          $v.form.tipos_vehiculo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.tipos_vehiculo"
                        placeholder="Seleccione Tipo Vehículo..."
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.tipos_vehiculo"
                        multiple
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn bg-primary col-md-1"
                  v-if="
                    !$v.form.$invalid &&
                      $store.getters.can('tep.liquidacionesInternos.create')
                  "
                  @click="save()"
                  :disabled="estadodis"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
            <!-- Card Aprobaciones -->
            <div
              class="card"
              v-if="
                $route.params.data_edit &&
                  $route.params.data_edit.tep_liquidaciones_internos_firmas
                    .length > 0
              "
            >
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Aprobaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="firma in $route.params.data_edit
                      .tep_liquidaciones_internos_firmas"
                    :key="firma.id"
                  >
                    <div class="card bg-light d-flex flex-fill">
                      <div class="card-header text-muted border-bottom-0">
                        <h5>
                          {{
                            firma.tipo_firma == 1
                              ? "Firma Contratista"
                              : firma.tipo_firma == 2
                              ? "Firma Interventor del Contrato"
                              : firma.tipo_firma == 3
                              ? "Firma Administrador del Contrato"
                              : ""
                          }}
                        </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <p>
                              Firmado por: <br /><strong>{{
                                firma.user.name
                              }}</strong>
                            </p>
                            <p class="text-center mr-5 ml-5">
                              <img
                                :src="uri_docs + firma.user.link_firma"
                                class="img-fluid"
                              />
                            </p>
                            <p>
                              Fecha: <br /><strong>{{
                                firma.fecha_firma
                              }}</strong>
                            </p>
                            <p>
                              Observación: <br />
                              {{ firma.observacion }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Resumen / Detalle -->
            <div class="card">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Resumen"
                    data-toggle="tab"
                    href="#Resumen"
                    >Resumen</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Detalle"
                    data-toggle="tab"
                    href="#Detalle"
                    >Detalle</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Resumen">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                          "
                          style="font-size: 11px"
                        >
                          <thead>
                            <tr>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.vehiculo"
                                  class="form-control form-control-sm"
                                  placeholder="Vehiculo"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.cond"
                                  class="form-control form-control-sm"
                                  placeholder="Documento Conductor"
                                />
                              </th>
                              <th rowspan="2">Conductor2</th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.op"
                                  class="form-control form-control-sm"
                                  placeholder="Operacion"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.tv"
                                  class="form-control form-control-sm"
                                  placeholder="Tipo Vehículo"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.turno"
                                  class="form-control form-control-sm"
                                  placeholder="Turno"
                                />
                              </th>

                              <th colspan="2">Turnos</th>
                              <th colspan="2">Horas Add</th>
                              <th colspan="2">KM Add</th>
                              <th colspan="2">Pernoctes</th>
                              <th rowspan="2">Costo Tot.</th>
                            </tr>
                            <tr>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(liqui, index) in filteredRowsDetail"
                              :key="index"
                            >
                              <td class="text-center">
                                <button
                                  type="button"
                                  class="btn btn-dark p-0 w-100"
                                  data-toggle="modal"
                                  data-target="#modal_detalle"
                                  style="font-size: 10px !important"
                                  @click="
                                    getDetalleVehis(
                                      liqui.tep_det_tarifa_interno_id,
                                      liqui.vehiculo_id,
                                      liqui.conductor_id,
                                      liqui.conductor2_id,
                                      liqui.adicional,
                                      liqui.area,
                                      liqui.operacion
                                    )
                                  "
                                >
                                  {{ liqui.vehiculo.placa }}
                                  <i
                                    class="fas fa-plus-circle text-warning"
                                    v-if="liqui.adicional"
                                  ></i>
                                </button>
                              </td>
                              <td v-if="liqui.conductor">
                                {{ liqui.conductor.nombres }}
                                {{ liqui.conductor.apellidos }}
                                <span class="badge badge-info">{{
                                  liqui.conductor.numero_documento
                                }}</span>
                              </td>
                              <td v-else></td>
                              <td v-if="liqui.conductor2">
                                {{ liqui.conductor2.nombres }}
                                {{ liqui.conductor2.apellidos }}
                                <span class="badge badge-info">{{
                                  liqui.conductor2.numero_documento
                                }}</span>
                              </td>
                              <td v-else></td>
                              <td>
                                {{ liqui.nOperacion }}
                                <b v-if="liqui.area">({{ liqui.nArea }})</b>
                              </td>
                              <td>{{ liqui.tipo_vehiculo.nombre }}</td>
                              <td>
                                {{
                                  liqui.tep_det_tarifa_interno.tarifa_interno
                                    .turno.nombre
                                }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_turnos }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoTurnos }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_horas_add }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoHoraAdd }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_km_add }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoKmAdd }}
                              </td>
                              <td class="text-right">
                                {{
                                  liqui.cant_pernoctes
                                    ? liqui.cant_pernoctes
                                    : 0
                                }}
                              </td>
                              <td class="text-right">
                                {{
                                  liqui.costo_pernoctes
                                    ? liqui.costoPernocte
                                    : "$ 0"
                                }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoTotal }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="
                                  table-warning
                                  text-right
                                  font-weight-bold
                                "
                                colspan="6"
                              >
                                TOTALES:
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.turnos_cant.toFixed(2) }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.turnos_cost }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.horasAdd_cant.toFixed(2) }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.horasAdd_cost }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.kmAdd_cant.toFixed(2) }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.kmAdd_cost }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.pernoctes_cant.toFixed(2) }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.pernoctes_cost }}
                              </td>
                              <td class="text-right table-warning">
                                {{ totalesFilter.costo_total }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Detalle">
                  <div class="card-body table-responsive">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                          "
                          style="font-size: 11px"
                        >
                          <thead>
                            <tr>
                              <th rowspan="2">
                                <input
                                  v-model="buscarFact.vehiculo"
                                  class="form-control form-control-sm"
                                  placeholder="Vehiculo"
                                  @keyup.enter="showFacturacion()"
                                />
                              </th>
                              <th rowspan="2">
                                Operación
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarFact.tv"
                                  class="form-control form-control-sm"
                                  placeholder="Tipo Vehículo"
                                  @keyup.enter="showFacturacion()"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarFact.turno"
                                  class="form-control form-control-sm"
                                  placeholder="Turno"
                                  @keyup.enter="showFacturacion()"
                                />
                              </th>
                              <th colspan="2">Turnos</th>
                              <th colspan="2">Horas Add</th>
                              <th colspan="2">KM Add</th>
                              <th colspan="2">Pernoctes</th>
                              <th rowspan="2">Costo Tot.</th>
                              <th rowspan="2">Liquidar</th>
                            </tr>
                            <tr>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(liqui, index) in detalleFacturacion"
                              :key="index"
                            >
                              <td class="text-center">
                                {{ liqui.vehiculo.placa }}
                              </td>
                              <td>
                                {{ liqui.nOperacion }}
                                <b v-if="liqui.area">({{ liqui.nArea }})</b>
                              </td>
                              <td>{{ liqui.tipo_vehiculo.nombre }}</td>
                              <td>
                                {{
                                  liqui.tep_det_tarifa_interno.tarifa_interno
                                    .turno.nombre
                                }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_turnos }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costTurno }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_horas_add }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoHoraAdd }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_km_add }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoKmAdd }}
                              </td>
                              <td class="text-right">
                                {{ liqui.cant_pernoctes }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoPernocte }}
                              </td>
                              <td class="text-right">
                                {{ liqui.costoTot }}
                              </td>
                              <td class="text-center">
                                <button
                                  type="button"
                                  class="btn btn-sm bg-gray-dark"
                                  data-toggle="modal"
                                  data-target="#modal_detalle_factura"
                                  @click="cargarDatoModal(liqui, 1)"
                                  v-if="
                                    liqui
                                      .tep_det_facturacion_internos_cecos_afes
                                      .length == 0
                                  "
                                >
                                  <i class="fas fa-credit-card"></i>
                                </button>
                                <button
                                  v-else
                                  type="button"
                                  class="btn btn-sm bg-success"
                                  data-toggle="modal"
                                  data-target="#modal_detalle_factura"
                                  @click="cargarDatoModal(liqui, 2)"
                                >
                                  <i class="fas fa-eye"></i>
                                </button>
                              </td>
                              <td
                                class="text-center"
                                v-if="
                                  liqui.tep_det_facturacion_internos_cecos_afes
                                    .length > 0
                                "
                              >
                                <input
                                  class="outline-success"
                                  v-if="liqui.check_presupuesto == 1"
                                  checked
                                  type="checkbox"
                                  v-model="check_presupuesto[index]"
                                  @change="
                                    ValidarPresu(
                                      liqui.id,
                                      check_presupuesto[index]
                                    )
                                  "
                                />
                                <input
                                  v-else
                                  class="outline-success"
                                  type="checkbox"
                                  v-model="check_presupuesto[index]"
                                  @change="
                                    ValidarPresu(
                                      liqui.id,
                                      check_presupuesto[index]
                                    )
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Km Acumulados -->
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Servicios que acumulan km</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="
                        table table-bordered table-striped table-hover table-sm
                      "
                      style="font-size: 11px"
                    >
                      <thead>
                        <tr>
                          <th rowspan="2">
                            <button
                              class="btn btn-primary"
                              @click="consolidarKmAcum()"
                              v-if="chk_acumulan_km.length > 0"
                              :disabled="estadodis"
                            >
                              <i class="fas fa-layer-group"></i>
                            </button>
                          </th>
                          <th rowspan="2">Vehículo</th>
                          <th rowspan="2">Conductor</th>
                          <th rowspan="2">Conductor2</th>
                          <th rowspan="2">Operación</th>
                          <th rowspan="2">Tipo Vehículo</th>
                          <th rowspan="2">Turno</th>
                          <th rowspan="2">Km recorridos</th>

                          <th colspan="2">Turnos</th>
                          <th colspan="2">Horas Add</th>
                          <th colspan="2">KM Add</th>
                          <th rowspan="2">Costo Tot.</th>
                        </tr>
                        <tr>
                          <th>Cant</th>
                          <th>Costo</th>
                          <th>Cant</th>
                          <th>Costo</th>
                          <th>Cant</th>
                          <th>Costo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(liqui, index) in detalleLiqui" :key="index">
                          <template
                            v-if="
                              liqui.tep_det_tarifa_interno.acumula_km &&
                                liqui.estado == 1
                            "
                          >
                            <td>
                              <input
                                type="checkbox"
                                v-model="chk_acumulan_km[index]"
                                :disabled="estadodis"
                              />
                            </td>
                            <td class="text-center">
                              <button
                                type="button"
                                class="btn btn-dark p-0 w-100"
                                data-toggle="modal"
                                data-target="#modal_detalle"
                                style="font-size: 10px !important"
                                @click="
                                  getDetalleVehis(
                                    liqui.tep_det_tarifa_interno_id,
                                    liqui.vehiculo_id,
                                    liqui.conductor_id,
                                    liqui.conductor2_id,
                                    liqui.adicional,
                                    liqui.area,
                                    liqui.operacion
                                  )
                                "
                              >
                                {{ liqui.vehiculo.placa }}
                                <i
                                  v-if="liqui.adicional"
                                  class="fas fa-plus-circle text-warning"
                                ></i>
                              </button>
                            </td>
                            <td>
                              {{ liqui.conductor.nombres }}
                              {{ liqui.conductor.apellidos }}
                              <span class="badge badge-info">{{
                                liqui.conductor.numero_documento
                              }}</span>
                            </td>
                            <td v-if="liqui.conductor2">
                              {{ liqui.conductor2.nombres }}
                              {{ liqui.conductor2.apellidos }}
                              <span class="badge badge-info">{{
                                liqui.conductor2.numero_documento
                              }}</span>
                            </td>
                            <td v-else></td>
                            <td>
                              {{ liqui.nOperacion }}
                              <b v-if="liqui.area">({{ liqui.nArea }})</b>
                            </td>
                            <td>{{ liqui.tipo_vehiculo.nombre }}</td>
                            <td>
                              {{
                                liqui.tep_det_tarifa_interno.tarifa_interno
                                  .turno.nombre
                              }}
                            </td>
                            <td class="text-right">
                              {{ liqui.cant_km_recorridos }}
                            </td>
                            <td class="text-right">{{ liqui.cant_turnos }}</td>
                            <td class="text-right">{{ liqui.costoTurnos }}</td>
                            <td class="text-right">
                              {{ liqui.cant_horas_add }}
                            </td>
                            <td class="text-right">
                              {{ liqui.costoHoraAdd }}
                            </td>
                            <td class="text-right">
                              <i
                                :class="
                                  liqui.tep_det_tarifa_interno.acumula_km
                                    ? 'far fa-check-circle text-success'
                                    : 'far fa-times-circle text-danger'
                                "
                              ></i>
                              {{ liqui.cant_km_add }}
                            </td>
                            <td class="text-right">{{ liqui.costoKmAdd }}</td>
                            <td class="text-right">{{ liqui.costoTotal }}</td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal Detalle Vehículos -->
    <div
      id="modal_detalle"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 table-responsive p-0">
              <table
                class="table table-bordered table-striped table-hover table-sm"
                style="font-size: 11px"
              >
                <thead>
                  <tr>
                    <th rowspan="2">#</th>
                    <th rowspan="2">ID</th>
                    <th rowspan="2">Placa</th>
                    <th rowspan="2">Fecha</th>
                    <th rowspan="2">Conductor</th>
                    <th rowspan="2">Conductor2</th>
                    <th rowspan="2">Operación</th>
                    <th rowspan="2">Tipo Vehículo</th>
                    <th rowspan="2">Turno</th>

                    <th rowspan="2">Costo</th>
                    <th colspan="2">Horas Add</th>
                    <th colspan="2">KM Add</th>
                    <th colspan="2">Pernoctes</th>
                    <th rowspan="2">Costo Tot.</th>
                  </tr>
                  <tr>
                    <th>Cant</th>
                    <th>Costo</th>
                    <th>Cant</th>
                    <th>Costo</th>
                    <th>Cant</th>
                    <th>Costo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(turno, index) in liquiDetalleVehis" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ turno.tep_turno_id }}</td>
                    <td class="text-center">{{ turno.vehiculo.placa }}</td>
                    <td class="text-center">{{ turno.fecha }}</td>
                    <td>
                      {{ turno.conductor.nombres }}
                      {{ turno.conductor.apellidos }}
                    </td>
                    <td>
                      {{
                        turno.conductor2
                          ? `${turno.conductor2.nombres} ${turno.conductor2.apellidos} `
                          : ""
                      }}
                    </td>
                    <td>
                      {{ turno.nOperacion }}
                      <b v-if="turno.area">({{ turno.nArea }})</b>
                    </td>
                    <td>{{ turno.tipo_vehiculo.nombre }}</td>
                    <td>
                      {{
                        turno.tep_det_tarifa_interno.tarifa_interno.turno.nombre
                      }}
                    </td>
                    <td class="text-right">{{ turno.valTurno }}</td>
                    <td class="text-right">{{ turno.conc_horas_extra }}</td>
                    <td class="text-right">
                      {{ turno.concCostHorasExtra }}
                    </td>
                    <td class="text-right">{{ turno.conc_km_add }}</td>
                    <td class="text-right">{{ turno.concCostKmAdd }}</td>
                    <td class="text-right">
                      {{ turno.pernocte ? turno.pernocte : 0 }}
                    </td>
                    <td class="text-right">
                      {{ turno.costoPernocte ? turno.costoPernocte : "$ 0" }}
                    </td>
                    <td class="text-right">
                      {{ turno.tot }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="table-warning text-right font-weight-bold"
                      colspan="9"
                    >
                      TOTALES:
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.turnosCost }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.horasAdd_cant.toFixed(2) }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.horasAddCost }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.kmAdd_cant.toFixed(2) }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.kmAddCost }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.pernoctes_cant.toFixed(2) }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.pernoctesCost }}
                    </td>
                    <td class="text-right table-warning">
                      {{ totalesVh.total }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- Modal Detalle Factura -->
    <div
      id="modal_detalle_factura"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Facturación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body pb-0"
            v-if="tipo_modal == 1 || !this.form.check"
          >
            <div class="row">
              <div class="form-group col-md-3">
                <label>Tipo Presupuesto</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  :class="[
                    $v.formCeAf.frm_tipo_presupuesto.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="formCeAf.frm_tipo_presupuesto"
                  placeholder="Tipo Presupuesto"
                  label="descripcion"
                  :options="listasForms.tipo_presupuesto"
                  :disabled="listasForms.tipo_presupuesto.length == 0"
                  @input="validaFormCecoAfe('tipo_presupuesto')"
                ></v-select>
              </div>
              <div class="form-group col-md-3">
                <label for="numero">Código Contable</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  :class="[
                    $v.formCeAf.frm_numero.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="formCeAf.frm_numero"
                  placeholder="Código Contable"
                  label="codigo_contable"
                  :options="listasForms.numero"
                  :disabled="
                    !formCeAf.frm_tipo_presupuesto ||
                      listasForms.numero.length == 0
                  "
                  @input="validaFormCecoAfe('numero')"
                ></v-select>
              </div>
              <div class="form-group col-md-6">
                <label for="responsable">Responsable</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  :class="[
                    $v.formCeAf.frm_responsable.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="formCeAf.frm_responsable"
                  placeholder="Responsable"
                  label="nombres"
                  :options="listasForms.respon"
                  :disabled="
                    !formCeAf.frm_numero || listasForms.respon.length == 0
                  "
                ></v-select>
              </div>
              <div class="form-group col-md-3">
                <label for="porcentaje">Cantidad Turnos</label>
                <div class="input-group">
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    @input="valCantTurnos()"
                    v-model="formCeAf.cant_turnos"
                    :class="
                      $v.formCeAf.cant_turnos.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="
                      !formCeAf.frm_tipo_presupuesto ||
                        !formCeAf.frm_numero ||
                        !formCeAf.frm_responsable
                    "
                  />
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="porcentaje">Porcentaje</label>
                <div class="input-group">
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    max="100"
                    @input="valPorcentaje()"
                    v-model="formCeAf.porcentaje"
                    :class="
                      $v.formCeAf.porcentaje.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="
                      !formCeAf.frm_tipo_presupuesto ||
                        !formCeAf.frm_numero ||
                        !formCeAf.frm_responsable
                    "
                  />
                  <div class="input-group-append">
                    <span class="input-group-text form-control-sm">%</span>
                  </div>
                </div>
              </div>
              <div class="form-group mt-4 col-md-6">
                <button
                  v-if="!$v.formCeAf.$invalid"
                  type="button"
                  class="btn btn-success"
                  data-html="true"
                  data-toggle="tooltip"
                  @click="cargarCeco()"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div
                class="col-md-12 text-center"
                :class="
                  total_porcentaje == 100
                    ? 'text-success'
                    : total_porcentaje < 100
                    ? 'text-muted'
                    : 'text-danger'
                "
              >
                <h4 class="mb-0">Porcentaje Total: {{ total_porcentaje }}%</h4>
              </div>
            </div>
          </div>
          <div
            class="modal-body pb-0 table-responsive"
            v-if="tipo_modal == 1 || !this.form.check"
          >
            <table
              id="tabla-pasajeros"
              class="table table-bordered table-striped table-hover table-sm"
            >
              <thead>
                <tr>
                  <th class="text-center">Tipo Presupuesto</th>
                  <th class="text-center">Código Contable</th>
                  <th class="text-center">Responsable</th>
                  <th class="text-center">Cant Turnos</th>
                  <th class="text-center">Porcentaje</th>
                  <th class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cecoAfe, index) in cecosAfes" :key="cecoAfe.id">
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        cecoAfe.tipo_presupuesto.numeracion == 1 ||
                        cecoAfe.tipo_presupuesto == 1
                          ? 'bg-info'
                          : cecoAfe.tipo_presupuesto.numeracion == 2 ||
                            cecoAfe.tipo_presupuesto == 2
                          ? 'bg-navy'
                          : 'bg-lime'
                      "
                      >{{ cecoAfe.nTipoPresupuesto }}</span
                    >
                  </td>
                  <td>
                    <span v-if="formActualizarCA && idActualizarCA == index">
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="[
                          $v.formCeAf2.frm_numero.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="formCeAf2.frm_numero"
                        placeholder="Código Contable"
                        label="codigo_contable"
                        :options="listasForms2.numero"
                        :disabled="listasForms2.numero.length == 0"
                        @input="validaFormCecoAfe2()"
                      ></v-select>
                    </span>
                    <span v-else>
                      {{ cecoAfe.numero.codigo_contable }}
                    </span>
                  </td>
                  <td>
                    <span v-if="formActualizarCA && idActualizarCA == index">
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="[
                          $v.formCeAf2.frm_responsable.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="formCeAf2.frm_responsable"
                        placeholder="Responsable"
                        label="nombres"
                        :options="listasForms2.respon"
                        :disabled="
                          !formCeAf2.frm_numero ||
                            listasForms2.respon.length == 0
                        "
                      ></v-select>
                    </span>
                    <span v-else>
                      {{ cecoAfe.responsable.nombres }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span v-if="formActualizarCA && idActualizarCA == index">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="cant_turnos"
                        v-model="formCeAf2.cant_turnos"
                        :class="
                          $v.formCeAf2.cant_turnos.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </span>
                    <span v-else> {{ cecoAfe.cant_turnos }} </span>
                  </td>
                  <td class="text-right">
                    <span v-if="formActualizarCA && idActualizarCA == index">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="porcentaje"
                        v-model="formCeAf2.porcentaje"
                        :class="
                          $v.formCeAf2.porcentaje.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </span>
                    <span v-else> {{ cecoAfe.porcentaje }}% </span>
                  </td>
                  <td class="text-right">
                    <span v-if="formActualizarCA && idActualizarCA == index">
                      <button
                        v-if="!$v.formCeAf2.$invalid"
                        type="button"
                        class="btn btn-sm bg-success"
                        @click="guardarActualizacionCecoAfe(index)"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                    </span>
                    <span v-else>
                      <button
                        type="button"
                        class="btn btn-sm bg-navy"
                        :disabled="formActualizarCA && idActualizarCA != index"
                        @click="verFormActualizarCecoAfe(index)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        :disabled="formActualizarCA && idActualizarCA != index"
                        @click="borrarCecoAfe(index)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="modal-body pb-0 table-responsive"
            v-if="tipo_modal != 1 && this.form.check"
          >
            <table
              id="tabla-pasajeros"
              class="table table-bordered table-striped table-hover table-sm"
            >
              <thead>
                <tr>
                  <th>Tipo ok presupuesto</th>
                  <th>Código Contable</th>
                  <th>Responsable</th>
                  <th>Cant Turnos</th>
                  <th>Porcentaje</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cecoAfe,
                  index) in data_modal.tep_det_facturacion_internos_cecos_afes"
                  :key="index"
                >
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        cecoAfe.tipo_presupuesto.numeracion == 1 ||
                        cecoAfe.tipo_presupuesto == 1
                          ? 'bg-info'
                          : cecoAfe.tipo_presupuesto.numeracion == 2 ||
                            cecoAfe.tipo_presupuesto == 2
                          ? 'bg-navy'
                          : 'bg-lime'
                      "
                      >{{ cecoAfe.nTipoPresupuesto }}</span
                    >
                  </td>
                  <td>
                    {{
                      cecoAfe.ceco_afe ? cecoAfe.ceco_afe.codigo_contable : ""
                    }}
                  </td>
                  <td>
                    {{
                      cecoAfe.responsable
                        ? `${cecoAfe.responsable.nombres} ${cecoAfe.responsable.apellidos}`
                        : ""
                    }}
                  </td>
                  <td clas="text-center">
                    {{ cecoAfe.cant_turnos ? cecoAfe.cant_turnos : "" }}
                  </td>
                  <td class="text-right">{{ cecoAfe.porcentaje }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="!this.form.check && sumaPorcentajes == 100"
          >
            <button class="btn bg-success" @click="saveFacturacionAfeCeco()">
              <i class="fas fa-paper-plane"></i><br />Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TepLiquidacionInternosForm",
  components: {
    vSelect,
    Loading,
  },

  data() {
    return {
      id: null,
      accion: "",
      metodo: "",
      firma_admin: 0,
      cargando: false,
      estadodis: false,
      sitio: {},
      empresa: {},
      tipo_modal: 1,
      data_modal: null,
      formDetCecos: {},
      check_presupuesto: [],
      incluye_iva: false,
      form: {
        sitio_id: null,
        empresa_id: null,
        operaciones: [],
        areas: [],
        tipos_vehiculo: [],
        fecha_ini: "",
        fecha_fin: "",
        estado: null,
        modal_id: null,
        incluye_iva: false,
      },
      liquidaciones: {},
      liquiDetalleVehis: [],
      detalleLiqui: [],
      chk_acumulan_km: [],
      detalleFacturacion: {},
      total_porcentaje: 0,
      listasForms: {
        sitios: [],
        empresas: [],
        estados: [],
        operaciones: [],
        areas: [],
        tipos_vehiculo: [],
        tipo_presupuesto: [],
        respon: [],
        numero: [],
      },
      listasForms2: {
        numero: [],
        respon: [],
      },
      totales: {
        turnos_cant: 0,
        turnos_cost: 0,
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        costo_total: 0,
      },
      totalesVh: {
        turnos_cant: 0,
        turnos_cost: 0,
        turnosCost: 0,
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        horasAddCost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        kmAddCost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        pernoctesCost: 0,
        costo_total: 0,
      },
      formActualizarCA: false,
      idActualizarCA: -1,
      formCeAf: {
        frm_tipo_presupuesto: null,
        frm_numero: null,
        frm_responsable: null,
        cant_turnos: null,
        porcentaje: null,
      },
      formCeAf2: {
        frm_tipo_presupuesto: null,
        frm_numero: null,
        frm_responsable: null,
        cant_turnos: null,
        porcentaje: null,
      },
      form1: {},
      cecosAfes: [],
      buscarDet: {
        vehiculo: "",
        cond: "",
        cond2: "",
        op: "",
        tv: "",
        turno: "",
      },
      buscarFact: {
        vehiculo: "",
        tv: "",
        turno: "",
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    if (
      this.form.operaciones &&
      this.form.operaciones.find((o) => o.numeracion === 2)
    ) {
      return {
        form: {
          sitio_id: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          empresa_id: {
            required,
          },
          estado: {
            required,
          },
          tipos_vehiculo: {
            required,
          },
          operaciones: {
            required,
          },
          areas: {
            required,
          },
        },
        formCeAf: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          cant_turnos: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        formCeAf2: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          cant_turnos: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
      };
    } else {
      return {
        form: {
          sitio_id: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          empresa_id: {
            required,
          },
          estado: {
            required,
          },
          tipos_vehiculo: {
            required,
          },
          operaciones: {
            required,
          },
        },
        formCeAf: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          cant_turnos: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        formCeAf2: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          cant_turnos: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
      };
    }
  },

  computed: {
    filteredRowsDetail() {
      if (this.detalleLiqui) {
        return this.detalleLiqui.filter((det) => {
          det.costoTurnos = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_turnos
          );
          det.costoHoraAdd = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_horas_add
          );
          det.costoKmAdd = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_km_add
          );
          det.costoPernocte = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_pernoctes
          );
          det.costoTotal = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_total
          );

          const vh = det.vehiculo.placa.toLowerCase();
          let cond = null;
          if (det.conductor != null) {
            cond = det.conductor.numero_documento.toLowerCase();
          }
          //const cond2 = det.conductor2.numero_documento.toLowerCase();
          const op = det.nOperacion.toLowerCase();

          const tv = det.tipo_vehiculo.nombre.toLowerCase();
          const turno = det.tep_det_tarifa_interno.tarifa_interno.turno.nombre.toLowerCase();
          const searchTermvh = this.buscarDet.vehiculo.toLowerCase();
          const searchTermcond = this.buscarDet.cond.toLowerCase();
          const searchTermcond2 = this.buscarDet.cond2.toLowerCase();
          const searchTermop = this.buscarDet.op.toLowerCase();
          const searchTermtv = this.buscarDet.tv.toLowerCase();
          const searchTermturno = this.buscarDet.turno.toLowerCase();
          let valArea = false;
          if (det.area != null) {
            const area = det.nArea.toLowerCase();
            valArea = area.includes(searchTermop);
          }

          return (
            vh.includes(searchTermvh) &&
            cond.includes(searchTermcond) &&
            // cond2.includes(searchTermcond2) &&
            (op.includes(searchTermop) || valArea) &&
            tv.includes(searchTermtv) &&
            turno.includes(searchTermturno)
          );
        });
      } else {
        return this.detalleLiqui;
      }
    },

    totalesFilter() {
      let totales = {
        turnos_cant: 0,
        turnos_cost: 0,
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        costo_total: 0,
      };
      if (this.filteredRowsDetail) {
        let matrix = this.filteredRowsDetail;
        let tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_turnos"] ? matrix[i]["cant_turnos"] : 0
          );
        }
        totales.turnos_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_turnos"] ? matrix[i]["costo_turnos"] : 0
          );
        }
        totales.turnos_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_horas_add"] ? matrix[i]["cant_horas_add"] : 0
          );
        }
        totales.horasAdd_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_horas_add"] ? matrix[i]["costo_horas_add"] : 0
          );
        }
        totales.horasAdd_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_km_add"] ? matrix[i]["cant_km_add"] : 0
          );
        }
        totales.kmAdd_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_km_add"] ? matrix[i]["costo_km_add"] : 0
          );
        }
        totales.kmAdd_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_pernoctes"] ? matrix[i]["costo_pernoctes"] : 0
          );
        }
        totales.pernoctes_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_total"] ? matrix[i]["costo_total"] : 0
          );
        }
        totales.costo_total = this.formatCurrency("es-CO", "COP", 2, tot);
      }
      return totales;
    },

    sumaPorcentajes() {
      return this.cecosAfes.reduce(
        (total, cecoAfe) => total + Number(cecoAfe.porcentaje),
        0
      );
    },
  },

  methods: {
    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    async buscarEmpresas() {
      await axios
        .get("api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    async buscarSitios() {
      await axios
        .get("api/admin/sitios/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
        this.form.bloque_id = this.sitio.bloque_id;
      }
    },

    async getEstados() {
      await axios.get("/api/lista/100").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getOperaciones() {
      await axios.get("/api/lista/80").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getTiposVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getDetalle() {
      await axios
        .get("/api/tep/liquidacionesInternos/showDetalle", {
          params: {
            liqui_id: this.id,
          },
        })
        .then((response) => {
          this.detalleLiqui = response.data;

          this.totales.turnos_cant = this.sumColumn(
            this.detalleLiqui,
            "cant_turnos"
          );
          this.totales.turnos_cost = this.sumColumn(
            this.detalleLiqui,
            "costo_turnos"
          );
          this.totales.horasAdd_cant = this.sumColumn(
            this.detalleLiqui,
            "cant_horas_add"
          );
          this.totales.horasAdd_cost = this.sumColumn(
            this.detalleLiqui,
            "costo_horas_add"
          );
          this.totales.kmAdd_cant = this.sumColumn(
            this.detalleLiqui,
            "cant_km_add"
          );
          this.totales.kmAdd_cost = this.sumColumn(
            this.detalleLiqui,
            "costo_km_add"
          );
          this.totales.pernoctes_cant = this.sumColumn(
            this.detalleLiqui,
            "cant_pernoctes"
          );
          this.totales.pernoctes_cost = this.sumColumn(
            this.detalleLiqui,
            "costo_pernoctes"
          );
          this.totales.costo_total = this.sumColumn(
            this.detalleLiqui,
            "costo_total"
          );
        });
    },

    async getDetalleVehis(
      tep_det_tarifa_interno_id,
      vehiculo_id,
      conductor_id,
      conductor2_id,
      adicional,
      area,
      operacion
    ) {
      this.cargando = true;
      let filtros = {
        tep_det_tarifa_interno_id,
        vehiculo_id,
        conductor_id,
        conductor2_id,
        adicional,
        area,
        operacion,
        operaciones: this.getCol(this.form.operaciones, "numeracion"),
        tipos_vehiculo: this.getCol(this.form.tipos_vehiculo, "id"),
        areas: this.getCol(this.form.areas, "numeracion"),
        sitio_id: this.form.sitio_id,
        empresa_id: this.form.empresa_id,
        fecha_ini: this.form.fecha_ini,
        fecha_fin: this.form.fecha_fin,
      };
      await axios
        .get("/api/tep/liquidacionesInternos/showVehiDetalle", {
          params: filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.liquiDetalleVehis = response.data;

          /* Variables Costos Turnos */
          this.totalesVh.turnos_cost = this.sumColumn(
            this.liquiDetalleVehis,
            "val_turno"
          );
          this.totalesVh.turnosCost = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.totalesVh.turnos_cost
          );

          /* Variables Cantidad Horas Adicionales */
          this.totalesVh.horasAdd_cant = this.sumColumn(
            this.liquiDetalleVehis,
            "conc_horas_extra"
          );

          /* Variables Costo Horas Adicionales */
          this.totalesVh.horasAdd_cost = this.sumColumn(
            this.liquiDetalleVehis,
            "conc_costo_horas_extra"
          );

          this.totalesVh.horasAddCost = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.totalesVh.horasAdd_cost
          );

          /* Variables Cantidad Km Adicionales */
          this.totalesVh.kmAdd_cant = this.sumColumn(
            this.liquiDetalleVehis,
            "conc_km_add"
          );

          /* Variables Costo Km Adicionales */
          this.totalesVh.kmAdd_cost = this.sumColumn(
            this.liquiDetalleVehis,
            "conc_costo_km_add"
          );

          this.totalesVh.kmAddCost = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.totalesVh.kmAdd_cost
          );

          /* Variables Cantidad Pernoctes */
          this.totalesVh.pernoctes_cant = this.sumColumn(
            this.liquiDetalleVehis,
            "pernocte"
          );

          /*  Variables Costos Pernoctes */
          this.totalesVh.pernoctes_cost = this.sumColumn(
            this.liquiDetalleVehis,
            "costo_pernocte"
          );

          this.totalesVh.pernoctesCost = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.totalesVh.pernoctes_cost
          );

          /* Variables Total */
          this.totalesVh.tot =
            parseFloat(this.totalesVh.turnos_cost) +
            parseFloat(this.totalesVh.horasAdd_cost) +
            parseFloat(this.totalesVh.kmAdd_cost) +
            parseFloat(this.totalesVh.pernoctes_cost);

          this.totalesVh.total = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.totalesVh.tot
          );

          for (let i = 0; i < this.liquiDetalleVehis.length; i++) {
            let tot = 0;
            tot +=
              parseFloat(this.liquiDetalleVehis[i].val_turno) +
              parseFloat(this.liquiDetalleVehis[i].conc_costo_horas_extra) +
              parseFloat(this.liquiDetalleVehis[i].conc_costo_km_add) +
              parseFloat(
                this.liquiDetalleVehis[i].costo_pernocte
                  ? this.liquiDetalleVehis[i].costo_pernocte
                  : 0
              );

            this.liquiDetalleVehis[i].tot = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              tot
            );

            let valTurno = this.liquiDetalleVehis[i].val_turno;
            this.liquiDetalleVehis[i].valTurno = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valTurno
            );

            let concCostHorasExtra = this.liquiDetalleVehis[i]
              .conc_costo_horas_extra;
            this.liquiDetalleVehis[i].concCostHorasExtra = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              concCostHorasExtra
            );

            let concCostKmAdd = this.liquiDetalleVehis[i].conc_costo_km_add;
            this.liquiDetalleVehis[i].concCostKmAdd = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              concCostKmAdd
            );

            let costoPernocte = this.liquiDetalleVehis[i].costo_pernocte;
            this.liquiDetalleVehis[i].costoPernocte = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoPernocte
            );
          }
        });
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validarLiquidacion() {
      if (this.form.operaciones.filter((o) => o.numeracion === 2) == 0) {
        this.form.areas = [];
      }
      this.form.opera = this.getCol(this.form.operaciones, "numeracion");
      axios({
        method: "GET",
        url: "/api/tep/liquidacionesInternos/valLiquidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (this.validar == "si") {
          this.$swal({
            icon: "error",
            title: `Ya existe una liquidación con el mismo sitio, fechas, operación y transportadora..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.sitio = {};
          this.form.operaciones = [];
          this.form.sitio_id = null;
          this.form.fecha_ini = null;
          this.form.fecha_fin = null;
          this.form.empresa_id = null;
        }
      });
    },

    getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    getNumeroContable(filtros = null) {
      this.listasForms.numero = [];
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.numero = response.data;
        });
    },

    validaFormCecoAfe(campo = null) {
      switch (campo) {
        case "tipo_presupuesto":
          if (this.formCeAf.frm_tipo_presupuesto) {
            if (
              this.formCeAf.frm_numero &&
              this.formCeAf.frm_numero.tipo_presupuesto !=
                this.formCeAf.frm_tipo_presupuesto.numeracion
            ) {
              this.formCeAf.frm_numero = null;
              this.formCeAf.frm_responsable = null;
            }
            this.getNumeroContable({
              tipo_presupuesto:
                this.formCeAf.frm_tipo_presupuesto.numeracion == 1 ? 1 : 2,
            });
          } else {
            // Se inicial las listas y campos
            this.listasForms.numero = [];
            this.formCeAf.frm_numero = null;
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
          }
          break;

        case "numero":
          if (this.formCeAf.frm_numero) {
            // Se valida si existe un funcionario dentro de la numeracion contable
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
            if (this.formCeAf.frm_numero.funcionario) {
              const data_respons = {
                id: this.formCeAf.frm_numero.funcionario.id,
                nombres: `${this.formCeAf.frm_numero.funcionario.nombres} ${this.formCeAf.frm_numero.funcionario.apellidos}`,
                user_id: this.formCeAf.frm_numero.funcionario.user_id,
              };
              this.listasForms.respon.push(data_respons);
              this.formCeAf.frm_responsable = data_respons;
            } else {
              this.getResponsable();
            }
          } else {
            // Se inicial las listas y campos
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
          }
          break;

        default:
          break;
      }
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/tep/liquidacionesInternos",
          data: this.form,
        })
          .then(async (response) => {
            if (response.data.error !== undefined) {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: response.data.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              if (response.data.pl_val.length > 0) {
                let serv_alert = "<table class='table'>";
                let liqui_acum = response.data.pl_val;
                for (let x = 0; x < liqui_acum.length; x++) {
                  serv_alert += "<tr>";
                  serv_alert += `<td>${liqui_acum[x].vehiculo.placa}</td>`;
                  serv_alert += `<td>${liqui_acum[x].nOperacion} <b>(${liqui_acum[x].nArea})</b></td>`;
                  serv_alert += `<td>${liqui_acum[x].cant_turnos}</td>`;
                  serv_alert += "</tr>";
                }
                serv_alert += "</table>";
                await this.$swal({
                  title:
                    "Se encontraron los siguientes servicios sin conciliar.",
                  html: serv_alert,
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                });
              }
              if (this.metodo == "PUT") {
                this.back();
              }
              this.$route.params.accion = "Editar";
              this.id = response.data.data_liqui.id;
              this.form.id = response.data.data_liqui.id;
              this.metodo = "PUT";
              await this.getDetalle();
              this.$swal({
                icon: "success",
                title: "Se actualizó la liquidación correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getCol(matrix, col) {
      var column = [];
      for (var i = 0; i < matrix.length; i++) {
        column.push(matrix[i][col]);
      }
      return column;
    },

    async getDetCecos() {
      await axios
        .get("/api/tep/liquidacionesInternos/showDetCecos", {
          params: {
            liqui_id: this.id,
          },
        })
        .then((response) => {
          this.formDetCecos = response.data;
        });
    },

    sumColumn(matrix, col) {
      let tot = 0;
      for (var i = 0; i < matrix.length; i++) {
        tot += parseFloat(matrix[i][col] ? matrix[i][col] : 0);
      }
      return tot;
    },

    async init() {
      this.cargando = true;
      await this.buscarSitios();
      await this.buscarEmpresas();
      await this.getEstados();
      await this.getAreas();
      await this.getTiposVehiculo();
      await this.getOperaciones();

      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;

      if (this.$route.params.accion == "Editar") {
        this.estadodis =
          this.$route.params.data_edit.estado == 2
            ? true
            : !(await this.$store.getters.can(
                "tep.liquidacionesInternos.edit"
              ));
        let dataoproute = this.$route.params.data_edit
          .tep_liquidaciones_internos_operaciones;
        if (dataoproute) {
          let opselect = [];
          let areaselect = [];
          await dataoproute.forEach((op) => {
            let find_op = this.listasForms.operaciones.find(
              (o) => o.numeracion === op.operacion
            );
            if (find_op !== undefined) {
              let find_op2 = opselect.find(
                (o) => o.numeracion === op.operacion
              );
              if (find_op2 === undefined) {
                opselect.push(find_op);
              }
            }

            if (op.operacion == 2) {
              let find_area = this.listasForms.areas.find(
                (o) => o.numeracion === op.area
              );
              if (find_area !== undefined) {
                let find_area2 = areaselect.find(
                  (o) => o.numeracion === op.area
                );
                if (find_area2 === undefined) {
                  areaselect.push(find_area);
                }
              }
            }
          });
          this.form.operaciones = opselect;
          this.form.areas = areaselect;
        }

        let listtv = this.$route.params.data_edit.tipos_vehiculos;
        if (listtv) {
          let tvselect = [];
          await listtv.forEach((t) => {
            let find_tv = listtv.find((tv) => tv.id === t.id);

            if (find_tv !== undefined) {
              let find_tv2 = tvselect.find((tv) => tv.id === t.id);
              if (find_tv2 === undefined) {
                tvselect.push(find_tv);
              }
            }
          });
          this.form.tipos_vehiculo = tvselect;
        }

        this.form.empresa_id = this.$route.params.data_edit.empresa_id;
        this.form.sitio_id = this.$route.params.data_edit.sitio_id;
        this.form.fecha_ini = this.$route.params.data_edit.fecha_ini;
        this.form.fecha_fin = this.$route.params.data_edit.fecha_fin;
        this.form.estado = this.$route.params.data_edit.estado;
        this.form.id = this.$route.params.data_edit.id;
        this.form.incluye_iva = this.$route.params.data_edit.incluye_iva;

        let find_emp = await this.listasForms.empresas.find(
          (e) => e.id === this.form.empresa_id
        );
        this.empresa = find_emp;

        let find_sit = await this.listasForms.sitios.find(
          (e) => e.id === this.form.sitio_id
        );
        this.sitio = find_sit;
        this.form.bloque_id = find_sit.bloque_id;

        await this.$route.params.data_edit.tep_liquidaciones_internos_firmas.forEach(
          async (firma) => {
            if (firma.tipo_firma == 3) {
              this.firma_admin = 1;
              await this.showFacturacion();
            }
          }
        );
        await this.getDetalle();
        await this.getDetCecos();
        this.metodo = "PUT";
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
      this.cargando = false;
    },

    back() {
      return this.$router.replace("/Tep/LiquidacionesInternos");
    },

    async showFacturacion() {
      await axios
        .get("/api/tep/liquidacionesInternos/showFacturacion", {
          params: {
            liqui_id: this.id,
            turno: this.buscarFact.turno,
            tv: this.buscarFact.tv,
            vehiculo: this.buscarFact.vehiculo,
          },
        })
        .then((response) => {
          this.detalleFacturacion = response.data;

          for (let k = 0; k < this.detalleFacturacion.length; k++) {
            let costTurno = this.detalleFacturacion[k].costo_turnos;
            this.detalleFacturacion[k].costTurno = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costTurno
            );

            let costoHoraAdd = this.detalleFacturacion[k].costo_horas_add;
            this.detalleFacturacion[k].costoHoraAdd = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoHoraAdd
            );

            let costoKmAdd = this.detalleFacturacion[k].costo_km_add;
            this.detalleFacturacion[k].costoKmAdd = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoKmAdd
            );

            let costoPernocte = this.detalleFacturacion[k].costo_pernoctes;
            this.detalleFacturacion[k].costoPernocte = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoPernocte
            );

            let costoTot = this.detalleFacturacion[k].costo_total;
            this.detalleFacturacion[k].costoTot = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoTot
            );
          }
        });
    },

    async ValidarPresu(liquiId, value) {
      if (value) {
        value = 1;
      } else {
        value = 0;
      }

      await axios({
        method: "PUT",
        url: "/api/tep/liquidacionesInternos/editCheckFacturacion/",
        data: { check: value, id: liquiId },
      }).then(async () => {
        await this.init();
        await this.$swal({
          icon: "success",
          title: "Presupuesto verificado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      });
    },

    async cargarCeco() {
      this.formActualizarCA = false;
      // Se agrega a nuestra lista
      if (this.formCeAf.frm_tipo_presupuesto.numeracion) {
        this.formCeAf.tipo_presupuesto = this.formCeAf.frm_tipo_presupuesto.numeracion;
      } else {
        this.formCeAf.tipo_presupuesto = this.formCeAf.frm_tipo_presupuesto;
      }
      this.cecosAfes.push({
        tep_det_facturacion_interno_id: this.form.modal_id,
        tipo_presupuesto: this.formCeAf.tipo_presupuesto,
        nTipoPresupuesto: this.formCeAf.frm_tipo_presupuesto.descripcion,
        numero: this.formCeAf.frm_numero,
        responsable: this.formCeAf.frm_responsable,
        cant_turnos: this.formCeAf.cant_turnos,
        porcentaje: this.formCeAf.porcentaje,
      });
      /* await this.porcentajeTotal(); */
      // Vaciamos el formulario de añadir
      this.formCeAf = {
        frm_tipo_presupuesto: null,
        frm_numero: null,
        frm_responsable: null,
        cant_turnos: null,
        porcentaje: null,
      };
    },

    verFormActualizarCecoAfe(cecosAfes_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarCA = cecosAfes_id;
      this.formCeAf2.frm_responsable = this.cecosAfes[cecosAfes_id].responsable;
      this.formCeAf2.frm_tipo_presupuesto = this.cecosAfes[
        cecosAfes_id
      ].tipo_presupuesto;
      this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
      this.formCeAf2.frm_numero = this.cecosAfes[cecosAfes_id].numero;
      this.formCeAf2.cant_turnos = this.cecosAfes[cecosAfes_id].cant_turnos
        ? this.cecosAfes[cecosAfes_id].cant_turnos
        : 0;

      // Mostramos el formulario
      this.formActualizarCA = true;

      // Se valida la lista del formulario
      this.getNumeroContable2({
        tipo_presupuesto:
          this.cecosAfes[cecosAfes_id].tipo_presupuesto.numeracion == 1 ? 1 : 2,
      });

      // Se valida si existe un funcionario dentro de la numeracion contable
      this.listasForms2.respon = [];
      if (this.cecosAfes[cecosAfes_id].numero.funcionario) {
        this.listasForms2.respon.push({
          id: this.cecosAfes[cecosAfes_id].numero.funcionario.id,
          nombres: `${this.cecosAfes[cecosAfes_id].numero.funcionario.nombres} ${this.cecosAfes[cecosAfes_id].numero.funcionario.apellidos}`,
          user_id: this.cecosAfes[cecosAfes_id].numero.funcionario.user_id,
        });
      } else {
        this.getResponsable2();
      }
    },

    getNumeroContable2(filtros = null) {
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms2.numero = response.data;
        });
    },

    getResponsable2() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms2.respon = response.data;
        });
    },

    validaFormCecoAfe2() {
      if (this.formCeAf2.frm_numero) {
        if (
          this.formCeAf2.frm_responsable &&
          this.formCeAf2.frm_responsable.id !=
            this.formCeAf2.frm_numero.funcionario_id
        ) {
          this.formCeAf2.frm_responsable = null;
        }
        // Se valida si existe un funcionario dentro de la numeracion contable
        this.listasForms2.respon = [];
        this.formCeAf2.frm_responsable = null;
        if (this.formCeAf2.frm_numero.funcionario) {
          const data_respons = {
            id: this.formCeAf2.frm_numero.funcionario.id,
            nombres: `${this.formCeAf2.frm_numero.funcionario.nombres} ${this.formCeAf2.frm_numero.funcionario.apellidos}`,
            user_id: this.formCeAf2.frm_numero.funcionario.user_id,
          };
          this.listasForms2.respon.push(data_respons);
          this.formCeAf2.frm_responsable = data_respons;
        } else {
          this.getResponsable2();
        }
      } else {
        // Se inicial las listas y campos
        this.listasForms2.respon = [];
        this.formCeAf2.frm_responsable = null;
      }
    },

    guardarActualizacionCecoAfe(cecosAfes_id) {
      // Se valida el valor del porcentaje
      let flag_porcentaje = this.total_porcentaje;
      flag_porcentaje -= Number(this.cecosAfes[cecosAfes_id].porcentaje);
      flag_porcentaje += Number(this.formCeAf2.porcentaje);

      if (flag_porcentaje > 100) {
        this.$swal({
          title: "Excedió la cantidad de ceco y afe permitida",
          text: `El límite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        flag_porcentaje -= Number(this.formCeAf2.porcentaje);
        this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
        flag_porcentaje += Number(this.formCeAf2.porcentaje);
      } else {
        // Ocultamos nuestro formulario de actualizar
        this.formActualizarCA = false;
        // Actualizamos los datos
        this.cecosAfes[
          cecosAfes_id
        ].frm_tipo_presupuesto = this.formCeAf2.tipo_presupuesto;
        this.cecosAfes[cecosAfes_id].frm_numero = this.formCeAf2.numero;
        this.cecosAfes[
          cecosAfes_id
        ].frm_responsable = this.formCeAf2.responsable;
        this.cecosAfes[cecosAfes_id].porcentaje = this.formCeAf2.porcentaje;
        this.cecosAfes[cecosAfes_id].cant_turnos = this.formCeAf2.cant_turnos;
      }
      // Se actualiza el valor del porcentaje
      this.total_porcentaje = flag_porcentaje;
    },

    borrarCecoAfe(cecosAfes_id) {
      this.$swal({
        title: "Está seguro de quitar el Ceco o Afe?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          this.total_porcentaje -= this.cecosAfes[cecosAfes_id].porcentaje;
          // Borramos de la lista
          this.cecosAfes.splice(cecosAfes_id, 1);
        }
      });
    },

    async porcentajeTotal() {
      if (this.formDetCecos[this.form.modal_id]) {
        this.total_porcentaje = 0;
        this.formDetCecos[this.form.modal_id].forEach((element) => {
          this.total_porcentaje =
            this.total_porcentaje + Number(element.porcentaje);
        });
      }

      if (this.formCeAf.porcentaje) {
        this.total_porcentaje =
          this.total_porcentaje + Number(this.formCeAf.porcentaje);
        // Se valida el total del porcentaje si es mayor a 100%
        if (this.total_porcentaje > 100) {
          this.$swal({
            title: "Excedio la cantidad de ceco y afe permitida",
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });
          this.total_porcentaje =
            this.total_porcentaje - Number(this.formCeAf.porcentaje);
          this.cecosAfes.pop();
        }
      }
    },

    valPorcentaje() {
      this.total_porcentaje = 0;
      if (this.cecosAfes.length > 0) {
        this.cecosAfes.forEach((element) => {
          this.total_porcentaje =
            this.total_porcentaje + Number(element.porcentaje);
        });
      }

      if (this.formCeAf.porcentaje) {
        this.total_porcentaje =
          this.total_porcentaje + Number(this.formCeAf.porcentaje);
        // Se valida el total del porcentaje si es mayor a 100%
        if (this.total_porcentaje > 100) {
          this.$swal({
            title: "Excedio la cantidad de ceco y afe permitida",
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });
          this.total_porcentaje =
            this.total_porcentaje - Number(this.formCeAf.porcentaje);
          this.formCeAf.porcentaje = null;
          /* this.cecosAfes.pop(); */
        }
      }
    },

    valCantTurnos() {
      if (this.formCeAf.cant_turnos < 0) {
        this.$swal({
          title: "La cantidad de turnos no debe ser menos a 0.",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.formCeAf.cant_turnos = null;
        this.formCeAf.porcentaje = null;
      }

      if (this.formCeAf.cant_turnos > this.data_modal.cant_turnos) {
        this.$swal({
          title: "Excedio la cantidad de turnos permitido.",
          text:
            `El limite de turnos son ` +
            this.data_modal.cant_turnos +
            `, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.formCeAf.cant_turnos = null;
        this.formCeAf.porcentaje = null;
      }
      this.formCeAf.porcentaje = parseFloat(
        (
          (100 / this.data_modal.cant_turnos) *
          this.formCeAf.cant_turnos
        ).toFixed(2)
      );
      this.valPorcentaje();
    },

    llenarcecoAfe() {
      let datos = {};
      let porcentaje = 0;
      for (let c = 0; c < this.form1.ceco_afe.length; c++) {
        porcentaje += this.form1.ceco_afe[c].porcentaje;
        datos = {
          tipo_presupuesto: this.form1.ceco_afe[c].tipo_presupuesto,
          responsable: this.form1.ceco_afe[c].funcionario,
          numero: this.form1.ceco_afe[c].numero,
          porcentaje: this.form1.ceco_afe[c].porcentaje,
          id: this.form1.ceco_afe[c].id,
        };
        this.cecosAfes.push(datos);
      }
      this.total_porcentaje = porcentaje;
    },

    getResponsable() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms.respon = response.data;
        });
    },

    async saveFacturacionAfeCeco() {
      let form = [];
      this.cecosAfes.forEach((ceaf) => {
        if (ceaf.id) {
          form.push({
            id: ceaf.id,
            tep_det_facturacion_interno_id: ceaf.tep_det_facturacion_interno_id,
            ceco_afe_id: ceaf.numero.id,
            tipo_presupuesto: ceaf.tipo_presupuesto,
            responsable: ceaf.responsable.id,
            numero: ceaf.numero.codigo_contable,
            cant_turnos: ceaf.cant_turnos,
            porcentaje: parseFloat(ceaf.porcentaje),
          });
        } else {
          form.push({
            id: null,
            tep_det_facturacion_interno_id: ceaf.tep_det_facturacion_interno_id,
            ceco_afe_id: ceaf.numero.id,
            tipo_presupuesto: ceaf.tipo_presupuesto,
            responsable: ceaf.responsable.id,
            numero: ceaf.numero.codigo_contable,
            cant_turnos: ceaf.cant_turnos,
            porcentaje: parseFloat(ceaf.porcentaje),
          });
        }
      });

      this.cargando = true;
      await axios({
        method: "POST",
        url: "/api/tep/liquidacionesInternos/saveFacturacionCecoAfe",
        data: { cecoAfe: form },
      })
        .then(async (response) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$refs.closeModal.click();
          await this.showFacturacion();
        })
        .catch(async (e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async cargarDatoModal(modal_data, tipo_modal) {
      this.form.modal_id = modal_data.id;
      this.form.check = modal_data.check_presupuesto;
      this.cecosAfes = [];
      this.total_porcentaje = 0;
      if (!this.form.check) {
        await modal_data.tep_det_facturacion_internos_cecos_afes.forEach(
          (ceaf) => {
            this.cecosAfes.push({
              id: ceaf.id,
              tep_det_facturacion_interno_id:
                ceaf.tep_det_facturacion_interno_id,
              tipo_presupuesto: ceaf.tipo_presupuesto,
              nTipoPresupuesto: ceaf.nTipoPresupuesto,
              numero: ceaf.ceco_afe,
              responsable: ceaf.responsable,
              cant_turnos: ceaf.cant_turnos,
              porcentaje: ceaf.porcentaje,
            });
            this.porcentajeTotal();
          }
        );
      }

      if (this.formDetCecos[this.form.modal_id]) {
        await this.formDetCecos[this.form.modal_id].forEach(
          (element, index) => {
            this.cecosAfes.push({
              tep_det_facturacion_interno_id: this.form.modal_id,
              tipo_presupuesto: element.tipo_presupuesto,
              nTipoPresupuesto: element.nTipoPresupuesto,
              numero: element.ceco_afe,
              responsable: element.responsable,
              porcentaje: element.porcentaje,
            });
            this.porcentajeTotal();
          }
        );
      }
      this.tipo_modal = tipo_modal;
      this.data_modal = modal_data;
      // Se suma el total de porcentajes
      let total = 0;
      if (this.tipo_modal == 2) {
        await this.data_modal.tep_det_facturacion_internos_cecos_afes.forEach(
          (ceaf) => (total += parseFloat(ceaf.porcentaje))
        );
        this.total_porcentaje = total;
      }
    },

    async consolidarKmAcum() {
      const detLiquiLoc = this.detalleLiqui;
      let liqui_acum = [];

      let limite_km_dia = 999999999;

      for (let i = 0; i < this.chk_acumulan_km.length; i++) {
        if (this.chk_acumulan_km[i]) {
          const dl = detLiquiLoc[i];
          liqui_acum.push(dl);
        }
      }

      if (liqui_acum.length > 0) {
        limite_km_dia = parseFloat(
          liqui_acum[0].tep_det_tarifa_interno.bolsa_km
        );
      }

      let serv_alert = "<table class='table'>";
      for (let x = 0; x < liqui_acum.length; x++) {
        serv_alert += "<tr>";
        serv_alert += `<td>${liqui_acum[x].vehiculo.placa}</td>`;
        serv_alert += `<td>${liqui_acum[x].nOperacion} <b>(${liqui_acum[x].nArea})</b></td>`;
        serv_alert += `<td>${liqui_acum[x].tep_det_tarifa_interno.tarifa_interno.turno.nombre}</td>`;
        serv_alert += "</tr>";
      }
      serv_alert += "</table>";
      await this.$swal({
        title: "Esta seguro de unir los servicios seleccionados?",
        html: serv_alert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Unir!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          let turnos = this.sumColumn(liqui_acum, "cant_turnos");

          let limite_km_mes = limite_km_dia * turnos;

          let kms_recorridos = this.sumColumn(liqui_acum, "cant_km_recorridos");
          let km_add_mes = kms_recorridos - limite_km_mes;

          if (kms_recorridos > limite_km_mes) {
            for (let j = 0; j < liqui_acum.length; j++) {
              let limite_km_mes_r =
                liqui_acum[j].tep_det_tarifa_interno.bolsa_km *
                liqui_acum[j].cant_turnos;
              let kms_recorridos_r = parseFloat(
                liqui_acum[j].cant_km_recorridos
              );
              let km_add_r = 0;

              if (kms_recorridos_r > limite_km_mes_r) {
                km_add_r = kms_recorridos_r - limite_km_mes_r;
              }
              liqui_acum[j].cant_km_add = km_add_r;
            }

            let km_add_xr = this.sumColumn(liqui_acum, "cant_km_add");

            for (let k = 0; k < liqui_acum.length; k++) {
              let porc = (liqui_acum[k].cant_km_add * 100) / km_add_xr;
              let km_add_f = Math.round((km_add_mes * porc) / 100);

              let costo_km_add =
                parseFloat(
                  liqui_acum[k].tep_det_tarifa_interno.valor_km_adicional
                ) * km_add_f;
              liqui_acum[k].costo_total =
                parseFloat(liqui_acum[k].costo_turnos) +
                parseFloat(costo_km_add) +
                parseFloat(liqui_acum[k].costo_horas_add);
              liqui_acum[k].cant_km_add = km_add_f;
              liqui_acum[k].costo_km_add = costo_km_add;
            }

            let data_save = [];

            for (let y = 0; y < liqui_acum.length; y++) {
              data_save.push({
                id: liqui_acum[y].id,
                cant_km_add: liqui_acum[y].cant_km_add,
                costo_km_add: liqui_acum[y].costo_km_add,
                costo_total: liqui_acum[y].costo_total,
              });
            }

            await axios({
              method: "PUT",
              url: "/api/tep/liquidacionesInternos/saveDetAcumulakm",
              data: { data: data_save },
            }).then(async () => {
              this.chk_acumulan_km = [];
              await this.getDetalle();
              this.$swal({
                icon: "success",
                title: "Datos de kms actualizados",
                text:
                  "Limite = " + limite_km_mes + " Total = " + kms_recorridos,
                position: "top-end",
                showConfirmButton: true,
              });

              this.cargando = false;
            });
          } else {
            this.$swal({
              icon: "warning",
              title: "No se encontraron kms adicionales",
              text: "Limite = " + limite_km_mes + " Total = " + kms_recorridos,
              position: "top-end",
              showConfirmButton: true,
            });
            this.cargando = false;
          }
        }
      });
    },
  },

  async mounted() {
    await this.init();
    await this.getTipoPresupuesto();
  },
};
</script>
